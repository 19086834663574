import { create } from "zustand";
import { EEntity } from "../enums/EServiceOrder";
import { ServicesOrdersStore } from "../models";
import {
    cancelOrder,
    getCancellationReasons,
    getOrderTypes,
    getServiceOrders,
} from "../services/ServiceOrdersService";

const initialState = {
    isLoadingServiceOrder: false,
    cancellationReasons: [],
    cancellationResponsibilities: [],
    loadingCancellationReasons: false,
    listServiceOrderTypes: [],
    codeErrorPermissionDeniedCancelReasons: null,
    updatedTableServiceOrder: false,
    entityUpdated: null,
    servicesOrders: {
        [EEntity.Client]: null,
        [EEntity.Lead]: null,
    },
};

export const useServiceOrders = create<ServicesOrdersStore>((set, get) => ({
    ...initialState,
    getServicesOrders: async (params) => {
        set(() => ({
            isLoadingServiceOrder: true,
            entityUpdated: params.entityUpdated ?? null,
        }));
        const { data } = await getServiceOrders(params);
        set((state) => ({
            isLoadingServiceOrder: false,
            servicesOrders: { ...state.servicesOrders, [params.entity]: data },
        }));
    },
    getServiceOrderTypes: async () => {
        const { data } = await getOrderTypes();
        const orderTypes = data.items
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((activity) => activity.is_active);
        set({ listServiceOrderTypes: orderTypes });
    },

    getCancelReasons: async () => {
        set({ loadingCancellationReasons: true });
        const { data } = await getCancellationReasons();
        const cancellationReasons = data.items.filter(
            (reason) =>
                reason.is_active && reason.available_in?.includes("estadium"),
        );
        const responsibilities = Array.from(
            new Set(cancellationReasons.map((item) => item.responsible)),
        );
        set({
            cancellationReasons,
            cancellationResponsibilities: responsibilities,
            loadingCancellationReasons: false,
        });
    },

    cancelServiceOrder: async (order, entity) => {
        await cancelOrder(order);
        get().updateTableServiceOrder(entity);
    },
    updateTableServiceOrder: (entity) =>
        set({ updatedTableServiceOrder: true, entityUpdated: entity }),
    cleanUpdateTableServiceOrder: () =>
        set({ updatedTableServiceOrder: false, entityUpdated: null }),
    cleanCodeErrorPermissionDeniedCancelReasons: () =>
        set({ codeErrorPermissionDeniedCancelReasons: null }),
}));
